@tailwind components;
@tailwind base;
@tailwind utilities;

html,
body {
  overscroll-behavior-x: none;
}

.parse-data-upload-button {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 4px;
  background-color: #5c8ef2;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.9;
  cursor: pointer;
}

.parse-data-upload-button:hover {
  background-color: #1152d5;
}

/* NOTE: Display importer as small component to show sticky action footer */
.nuvo-importer-wrapper {
  max-height: 900px;
  overflow: auto;
  display: flex;
}
/* NOTE: Display importer as small component to show sticky action footer */
.nuvo-importer-wrapper #nuvo-app-root-element {
  width: 100%;
}
